import React from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navigation from './components/Navigation';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import KPop from './pages/KPop';
import KDrama from './pages/KDrama';
import KBeauty from './pages/KBeauty';
import KFood from './pages/KFood';
import KFashion from './pages/KFashion';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FF69B4', // Hot Pink
      light: '#FFB6C1', // Light Pink
      dark: '#FF1493', // Deep Pink
    },
    secondary: {
      main: '#9370DB', // Medium Purple
      light: '#E6E6FA', // Lavender
      dark: '#8A2BE2', // Blue Violet
    },
    background: {
      default: '#FFF0F5', // Lavender Blush
      paper: '#FFFFFF',
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#757575',
    },
  },
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          textTransform: 'none',
          padding: '8px 24px',
          fontSize: '1rem',
          boxShadow: '0 2px 8px rgba(255, 105, 180, 0.25)',
          '&:hover': {
            boxShadow: '0 4px 12px rgba(255, 105, 180, 0.35)',
          },
        },
        containedPrimary: {
          background: 'linear-gradient(45deg, #FF69B4 30%, #FF1493 90%)',
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #9370DB 30%, #8A2BE2 90%)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 20px rgba(255, 105, 180, 0.15)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(45deg, #FF69B4 30%, #9370DB 90%)',
          boxShadow: '0 2px 12px rgba(255, 105, 180, 0.25)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <>
                    <Navigation />
                    <Dashboard />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/kpop"
              element={
                <ProtectedRoute>
                  <>
                    <Navigation />
                    <KPop />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/kdrama"
              element={
                <ProtectedRoute>
                  <>
                    <Navigation />
                    <KDrama />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/kbeauty"
              element={
                <ProtectedRoute>
                  <>
                    <Navigation />
                    <KBeauty />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/kfood"
              element={
                <ProtectedRoute>
                  <>
                    <Navigation />
                    <KFood />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/kfashion"
              element={
                <ProtectedRoute>
                  <>
                    <Navigation />
                    <KFashion />
                  </>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
