import React, { useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Box,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

const categories = [
  'Recipe',
  'Restaurant',
  'Snack',
  'Beverage',
  'Street Food',
  'Traditional Dish',
];

const statuses = [
  'Want to Try',
  'Currently Cooking',
  'Tried',
  'Favorite',
  'Recipe Mastered',
];

function KFood() {
  const [entries, setEntries] = useState([]);
  const [open, setOpen] = useState(false);
  const [newEntry, setNewEntry] = useState({
    title: '',
    category: '',
    status: '',
    rating: '',
    notes: '',
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    setEntries([...entries, { ...newEntry, id: Date.now() }]);
    setNewEntry({
      title: '',
      category: '',
      status: '',
      rating: '',
      notes: '',
    });
    handleClose();
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" component="h1">
          K-Food Diary
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Add Entry
        </Button>
      </Box>

      <Grid container spacing={3}>
        {entries.map((entry) => (
          <Grid item xs={12} sm={6} md={4} key={entry.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{entry.title}</Typography>
                <Typography color="textSecondary">
                  Category: {entry.category}
                </Typography>
                <Typography color="textSecondary">
                  Status: {entry.status}
                </Typography>
                {entry.rating && (
                  <Typography>Rating: {entry.rating}/5</Typography>
                )}
                {entry.notes && (
                  <Typography variant="body2">{entry.notes}</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New K-Food Entry</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            fullWidth
            value={newEntry.title}
            onChange={(e) =>
              setNewEntry({ ...newEntry, title: e.target.value })
            }
          />
          <TextField
            select
            margin="dense"
            label="Category"
            fullWidth
            value={newEntry.category}
            onChange={(e) =>
              setNewEntry({ ...newEntry, category: e.target.value })
            }
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin="dense"
            label="Status"
            fullWidth
            value={newEntry.status}
            onChange={(e) =>
              setNewEntry({ ...newEntry, status: e.target.value })
            }
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            type="number"
            margin="dense"
            label="Rating (1-5)"
            fullWidth
            value={newEntry.rating}
            onChange={(e) =>
              setNewEntry({ ...newEntry, rating: e.target.value })
            }
            inputProps={{ min: 1, max: 5 }}
          />
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={4}
            value={newEntry.notes}
            onChange={(e) =>
              setNewEntry({ ...newEntry, notes: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            Add Entry
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default KFood;
