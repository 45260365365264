import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
} from '@mui/material';
import {
  MusicNote,
  Movie,
  Face,
  Restaurant,
  Checkroom,
} from '@mui/icons-material';

const categories = [
  {
    title: 'K-Pop',
    icon: <MusicNote sx={{ fontSize: 40 }} />,
    description: 'Track your favorite K-Pop artists, songs, albums, and concerts',
    path: '/kpop',
    color: '#ff0066',
  },
  {
    title: 'K-Drama',
    icon: <Movie sx={{ fontSize: 40 }} />,
    description: "Keep track of dramas you've watched or want to watch",
    path: '/kdrama',
    color: '#6600ff',
  },
  {
    title: 'K-Beauty',
    icon: <Face sx={{ fontSize: 40 }} />,
    description: 'Document your K-beauty products and skincare routines',
    path: '/kbeauty',
    color: '#ff6699',
  },
  {
    title: 'K-Food',
    icon: <Restaurant sx={{ fontSize: 40 }} />,
    description: 'Save Korean recipes and track your cooking adventures',
    path: '/kfood',
    color: '#ff3300',
  },
  {
    title: 'K-Fashion',
    icon: <Checkroom sx={{ fontSize: 40 }} />,
    description: 'Keep up with Korean fashion trends and styles',
    path: '/kfashion',
    color: '#9933ff',
  },
];

function Dashboard() {
  return (
    <Container>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to K-Diary
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Your personal space to track and organize all things Korean culture
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} md={4} key={category.title}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    color: category.color,
                  }}
                >
                  {category.icon}
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{ ml: 1 }}
                  >
                    {category.title}
                  </Typography>
                </Box>
                <Typography>{category.description}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={RouterLink}
                  to={category.path}
                  size="small"
                  color="primary"
                >
                  Open {category.title}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Dashboard;
